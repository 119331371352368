import { ContainerDesktop } from "components/Container";
import { Logo } from "components/Logo";
import React from "react";

const screen = typeof window !== "undefined" && window.innerWidth;
const isMobile = screen < 770;

const FooterBusiness: React.FC = ({}) => {
  return (
    <footer>
      <ContainerDesktop
        customStyle={{
          background: "linear-gradient(180deg, #F6F3FD 0%, #FFFFFF 45.5%)",
        }}
        classBackgroundFull="py-20 h-full flex items-center justify-center"
        className="flex"
        useAuto
      >
        {isMobile ? (
          <div className="flex flex-col justify-between items-center w-full gap-12 text-purple-50">
            <Logo />
            <div className="font-light">
              #LearnBetterGoFaster with goKampus!
            </div>
            <div className="font-light text-b1">
              © 2025 PT. Sistem Sarjana Sejahtera.
            </div>
          </div>
        ) : (
          <div className="flex flex-row justify-between items-center w-full gap-12 text-purple-50">
            <div className="font-light">
              #LearnBetterGoFaster with goKampus!
            </div>
            <Logo />
            <div className="font-light text-b1">
              © 2025 PT. Sistem Sarjana Sejahtera.
            </div>
          </div>
        )}
      </ContainerDesktop>
    </footer>
  );
};

export default FooterBusiness;
